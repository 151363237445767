:root {
	--base-01: 255, 255, 255;
	--base-02: 87, 125, 255;
	--base-03: 78, 112, 229;
	--base-04: 69, 100, 204;
	--base-05: 61, 87, 178;
	--base-06: 52, 75, 153;
	--base-07: 43, 62, 128;
	--base-08: 35, 50, 102;
	--base-09: 26, 37, 77;
	--base-10: 17, 25, 51;
	--base-11: 9, 12, 26;
	--base-12: 0, 0, 0;
	--primary-accent-blue: 52, 75, 153;
	--primary-accent-blue-light: 200, 207, 234;
	--primary-accent-blue-dark: 46, 63, 128;
	//--primary-accent-blue-pale: 79, 110, 136;
	--primary-accent-yellow: 255, 212, 102;
	--primary-accent-yellow-light: 255, 226, 153;
	--primary-accent-yellow-dark: 229, 191, 92;
	--primary-accent-cyan: 106, 212, 205;
	--primary-accent-white: 255, 255, 255;
	//--primary-accent-grey-light: 248, 249, 253;
	//--primary-accent-grey-dark: 181, 195, 206;
	--primary: var(--primary-accent-blue);
	--primary-dark: var(--primary-accent-blue-dark);
	--primary-light: var(--primary-accent-blue-light);
	//--primary-light-2: var(--primary-accent-blue-pale);
	--secondary: var(--primary-accent-yellow);
	--secondary-light: var(--primary-accent-yellow-light);
	--secondary-dark: var(--primary-accent-yellow-dark);

	// Font Colors
	--font-brand: var(--primary-accent-yellow);
	--font-dark: 37, 50, 102;
	--font-light: var(--base-01);
	--font-supportive: 200, 207, 234;
	--font-disabled: 138, 164, 255;

	// Background-images
	--nav-bg: linear-gradient(rgba(var(--primary-dark), 1), rgba(var(--primary), 1));
	--nav-active: linear-gradient(to bottom, rgba(var(--base-01), 1), rgba(var(--base-02), 1));

	// System Colors
	--error: 242, 130, 139;
	--success: 0, 199, 107;
	--info: 0, 130, 235;
	--pending: 249, 174, 65;
	--purple: 167, 100, 255;

	// Live Casino
	--active-players-color: 248, 249, 253;
	--full-room-color: 111, 112, 116;

	// --notification-text-color: defaults to rgba(var(--base-02), 1);

	// Progress Meter
	--risk-level-base: var(--primary-accent-blue-dark);
	--risk-level-0: var(--primary-accent-blue-dark);
	--risk-level-1: var(--success);
	--risk-level-2: var(--success);
	--risk-level-3: var(--pending);
	--risk-level-4: var(--error);

	// Type Vars
	--display: normal normal 800 7rem 'Mulish', sans-serif;
	--display-2: normal normal 800 5rem/1.26 'Mulish', sans-serif;
	--display-3: normal normal 800 3.6rem/1.222 'Mulish', sans-serif;
	--h1: normal normal 800 2.8rem/1.285 'Mulish', sans-serif;
	--h2: normal normal 800 2.2rem/1.272 'Mulish', sans-serif;
	--h3: normal normal 800 1.9rem/1.263 'Mulish', sans-serif;
	--h4-bold: normal normal 800 1.7rem/1.412 'Mulish', sans-serif;
	--h4-regular: normal normal 400 1.7rem/1.412 'Mulish', sans-serif;
	--heading-5: normal normal 400 16px/1.5 'Mulish', sans-serif;
	--label-regular: normal normal 400 1.5rem/1.333 'Mulish', sans-serif;
	--label-bold: normal normal 800 1.5rem/1.333 'Mulish', sans-serif;
	--caption: normal normal 400 1.2rem/1.333 'Mulish', sans-serif;
	--caption-bold: normal normal 800 1.2rem/1.333 'Mulish', sans-serif;
	--small: normal normal 400 1.1rem/1.272 'Mulish', sans-serif;
	--small-bold: normal normal 800 1.1rem/1.272 'Mulish', sans-serif;
	--brand-text-transform: uppercase;

	// Font Changes 4
	// --top-right-corner-link: defaults to --label-regular;
	//--product-icon-text: defaults to --label-regular;
	--footer-links: var(--label-bold);

	// Notification Inbox
	// Notification counter
	--unread-message-counter-bg-color: var(--primary-accent-cyan);
	--unread-message-counter-text-color: var(--font-dark);

	// Notification Header
	--my-notifications-header-bg-color: var(--primary-accent-blue);
	--my-notifications-heading-color: var(--base-01);
	--my-notifications-mark-all-read-color: var(--primary-accent-yellow);
	--my-notification-border-bottom: var(--primary-accent-blue-dark);
	--my-notification-category-color: var(--font-light);

	// Notification: Unread message
	--unread-message-notifier-color: var(--primary-accent-blue-light);
	--unread-message-title-color: var(--font-brand);
	--unread-message-title-slider-not-active: var(--font-light);
	--unread-message-subtitle-color: var(--font-supportive);
	--unread-message-date-color: var(--font-supportive);

	// Notification: Read message
	--read-message-color: var(--font-supportive);
	--read-message-title-color: var(--font-light);

	// Notification: No Data
	--no-notifications-icon-color: var(--primary-accent-yellow);
	--no-notifications-heading-color: var(--base-01);
	--no-notifications-subheading-color: var(--base-01);

	//embed link
	--embed-link-color: var(--primary-accent-yellow);

	// Notification: Full screen popup
	--notification-popup-bg: var(--primary-accent-blue);
	--notification-popup-title: var(--primary-accent-yellow);
	--notification-popup-subtitle: var(--base-01);
	--notification-popup-close-icon: var(--primary-accent-yellow);
	--notification-popup-button-bg: var(--primary-accent-yellow);
	--notification-popup-button-text: var(--font-dark);

	// Web-push
	--web-push-popup-bg: var(--primary-accent-blue-dark);
	--web-push-popup-title: var(--base-01);
	--web-push-popup-subtitle: var(--base-01);
	--web-push-popup-button-bg: var(--primary-accent-yellow);
	--web-push-popup-button-text: var(--primary-accent-yellow);

	// Source Of Wealth
	--source-of-wealth-help-text: var(--font-light);
	--source-of-wealth-form-bg: var(--primary-dark);
	--source-of-wealth-form-text: var(--font-light);
	--source-of-wealth-dropdown-bg: var(--primary-dark);
	--source-of-wealth-dropdown-border: var(--primary-accent-blue-dark);

	// Landing Page
	--login-btn-bg: rgba(var(--primary-accent-blue-dark), 1);
	--login-btn-text: rgba(var(--primary-accent-white), 1);
	--landing-page-logo: rgba(var(--primary-accent-yellow), 1);
	--landing-page-logo-mt: 167px;
	--landing-page-title: rgba(var(--font-light), 1);
	--product-icon-bg: rgba(var(--primary-accent-blue-dark), 1);
	--product-icon: rgba(var(--primary-accent-white), 1);
	--product-icon-label: rgba(var(--primary-accent-white), 1);
	--terms-and-condition_bg: rgba(var(--primary-accent-blue-dark), 1);
	--bonus-terms-text: rgba(var(--font-light), 1);
	--bonus-terms-link: rgba(var(--primary-accent-yellow), 1);
	--welcome-popup-bg: rgba(var(--primary), 1);
	--welcome-popup-text-color: rgba(var(--primary-accent-yellow), 1);

	// Head
	--head-bg: rgba(var(--primary-accent-blue-dark), 1);
	--hamburger-menu: rgba(var(--primary-accent-yellow), 1);
	--page-head-logo: rgba(var(--primary-accent-yellow), 1);
	--page-head-logo-height: 27px;
	--page-head-title: rgba(var(--base-01), 1);
	// --account-text:  defaults to --base-02
	--shop-balance-bg: rgba(var(--primary-accent-blue), 1);
	--balance-icons: rgba(var(--primary-accent-blue), 1);
	--account-icon: rgba(var(--base-01), 1);
	--balance-circle: rgba(var(--primary-accent-blue), 1);
	--balance-icon-wrapper: rgba(var(--primary-accent-yellow), 1);
	--balance-text: rgba(var(--base-01), 1);
	--page-head-link: rgba(var(--primary-accent-yellow), 1);
	--page-head-icon: rgba(var(--primary-accent-yellow), 1);
	--balance-header-icon: rgba(var(--primary-accent-blue-dark), 1);
	--balance-fill-icon-color: rgba(var(--primary-accent-white), 1);
	--header-balance-dropdown-icon-color: rgba(var(--primary-accent-yellow), 1);

	// Chip
	--balance-chip-circle: rgba(var(--primary-accent-blue), 1);
	--balance-chip-text: var(--balance-text);
	--balance-chip-icon: rgba(var(--font-dark), 1);
	--balance-icon-chip-wrapper: rgba(var(--primary-accent-cyan), 1);

	// Regulations Header
	--panic-wrapper-bg: rgba(var(--primary-accent-blue-light), 1);
	//--activeProductBg:  defaults to --info
	--notActiveProductBg: rgba(var(--info), 1);
	--notActiveProductSvg: rgba(var(--primary-accent-blue-light), 1);

	// Buttons
	--button-primary-bg: rgba(var(--primary-accent-yellow), 1);
	--button-primary-bg-hover: rgba(var(--primary-accent-yellow-dark), 1);
	--button-primary-text: rgba(var(--font-dark), 1);
	--button-secondary-bg: rgba(var(--primary-accent-blue-light), 1);
	--button-secondary-text: rgba(var(--font-light), 1);
	--button-wrapper-bg: rgba(var(--primary), 1);
	--button-wrapper-bg-light: transparent;
	--button-register: rgba(var(--primary-accent-yellow), 1);
	--button-register-text: rgba(var(--font-dark), 1);
	--button-register-border-radius: 100px;
	--button-pag: rgba(var(--primary-accent-yellow), 1);
	--button-pag-text-color: rgba(var(--font-dark), 1);
	--support-button: rgba(var(--primary-accent-blue-dark), 1);
	--support-button-content: rgba(var(--base-01), 1);
	--button-border-radius: 100px;
	--button-disabled-bg: rgba(var(--primary-accent-yellow-light), 1);
	--button-disabled-text: rgba(var(--font-disabled), 1);
	--button-success-bg: rgba(var(--base-11), 0.25);
	--button-success-color: rgba(var(--base-02), 1);

	// Badges
	--badge-primary-bg: rgba(var(--primary), 1);
	--badge-primary-text: rgba(var(--base-02), 1);
	--badge-new-bg: rgba(var(--primary-accent-cyan), 1);
	--badge-new-text: rgba(var(--primary-accent-blue-dark), 1);
	--badge-dark-bg: rgba(var(--base-12), 1);
	--badge-dark-text: rgba(var(--base-01), 1);
	--badge-secondary-bg: rgba(var(--primary-accent-cyan));
	--badge-secondary-text: rgba(var(--font-dark), 1);

	// StakeInput
	--stake-input-button-bg-color: rgba(var(--primary-accent-blue), 1);
	--stake-input-bg-color: rgba(var(--primary-accent-blue-dark), 1);
	--stake-input-text-color: rgba(var(--base-02), 1);
	--stake-input-icon-color: rgba(var(--primary-accent-yellow), 1);
	--stake-input-button-disabled-color: rgba(var(--primary-accent-blue), 1);
	--stake-input-icon-disabled-color: rgba(var(--font-disabled), 1);

	// Select
	--select-bg-color: rgba(var(--primary-light), 1);
	--select-border-bottom-color: rgba(var(--base-01), 1);
	--select-selected-option-color: rgba(var(--base-01), 1);
	--select-option-color: rgba(var(--base-10), 1);
	--select-option-bg-color: rgba(var(--base-01), 1);
	--select-legend-color: rgba(var(--primary-accent-yellow), 1);
	--select-arrow-down-color: rgba(var(--primary-accent-yellow), 1);

	// Pill
	--pill-bg: rgba(var(--primary-accent-blue), 1);
	--pill-label-color: rgba(var(--font-light), 1);
	--pill-checked-bg: rgba(var(--primary-accent-yellow), 1);
	--pill-checked-label-color: rgba(var(--font-dark), 1);

	// Link List
	--link-list: rgba(var(--primary-accent-blue-dark), 1);
	--list-detail-value: rgba(var(--font-light), 1);
	--list-detail-value-font: var(--label-regular);

	// Borders
	--list-border-bottom: rgba(var(--primary-accent-blue-dark), 1);
	--list-border-bottom-on-dark: rgba(var(--primary-accent-blue), 1);

	// Filters 12
	--filter-bg: rgba(var(--primary-accent-blue-dark), 1);
	--filter-border-primary: solid 1px rgba(var(--primary-accent-blue), 1);
	--filter-bg-secondary: rgba(var(--primary-accent-blue), 1);
	--filter-border-secondary: 0;
	--filter-idle: rgba(var(--base-01), 1);
	--filter-idle-secondary: rgba(var(--base-01), 1);
	--filter-disabled: rgba(var(--base-05), 1);
	--filter-active: rgba(var(--primary-accent-yellow), 1);
	--filter-active-secondary: rgba(var(--primary-accent-yellow), 1);
	--filter-bar-active: rgba(var(--primary-accent-yellow), 1);
	--filter-bar-active-secondary: rgba(var(--primary-accent-yellow), 1);
	--filter-hover: rgba(var(--primary-accent-yellow), 1);
	--filter-hover-secondary: rgba(var(--primary-accent-yellow), 1);
	--filter-support-links: rgba(var(--font-supportive), 1);

	// Mobile search input field
	--search-bg: rgba(var(--primary-accent-blue), 1);
	--search-text: rgba(var(--base-01), 1);
	--search-text-small: rgba(var(--primary-accent-blue-light), 1);
	--search-text-font: normal normal 400 1.6rem/1.335 'Mulish', sans-serif;
	--search-icon: rgba(var(--primary-accent-yellow), 1);
	--search-icon-bg-color: var(--submenu-item-bg);
	--search-cancel-font: var(--label-regular);
	--search-cancel-color: rgba(var(--primary-accent-yellow), 1);

	// Mobile search results
	--search-results-bg: rgba(var(--primary-accent-blue), 1);
	--search-result-item-bg: rgba(var(--primary-accent-blue-dark), 1);
	--search-results-caption: rgba(var(--font-light), 1);
	--search-results-caption-font: var(--label-bold);
	--search-results-more: rgba(var(--primary), 1);
	--search-no-results-text-font: var(--caption);
	--search-no-results-text-color: rgba(var(--base-01), 1);
	--search-ph-gradient-bottom: rgba(var(--base-02), 0.3);
	--search-ph-gradient-top: rgba(var(--base-02), 1);
	--search-ph-mask: rgba(var(--base-01), 1);

	// Custom checkboxes
	--custom-checkbox-border-color: var(--font-supportive);
	--custom-checked-checkbox-bg: var(--primary-accent-yellow);
	--custom-checked-checkbox-border-color: var(--primary-accent-yellow);
	--custom-checked-checkmark-color: var(--base-12);
	--custom-checked-disabled-checkbox-bg: var(--font-disabled);
	--custom-checked-disabled-checkbox-border-color: var(--font-disabled);
	--custom-checked-disabled-checkbox-checkmark: var(--font-dark);

	// Custom radio buttons
	--custom-radio-border: var(--font-supportive);
	--custom-checked-radio-bg: var(--primary-accent-yellow);
	--custom-checked-radio-border-color: var(--primary-accent-yellow);
	--custom-disabled-radio-border-color: var(--font-disabled);
	--custom-checked-disabled-radio-bg: var(--font-disabled);
	--custom-checked-disabled-radio-border-color: var(--font-disabled);

	// Custom select/dropdown
	--custom-select-icon: rgba(var(--primary-accent-yellow), 1);
	--custom-select-icon-option: rgba(var(--primary-accent-blue-light), 1);
	--custom-select-options-bg: rgba(var(--primary-accent-blue-dark), 1);
	--custom-select-options-icon: rgba(var(--primary-accent-grey-dark), 1);
	--custom-select-options-icon--selected: rgba(var(--primary-accent-grey-dark), 1);
	--custom-select-split-line-color: rgba(var(--primary-accent-blue), 1);

	// Desktop search input field
	--search-bg-large: rgba(var(--primary-accent-blue-dark), 1);
	--search-text-large: rgba(var(--font-supportive), 1);
	--search-text-desktop-placeholder: rgba(var(--font-supportive), 1);
	--search-icon-large: rgba(var(--primary-accent-yellow), 1);
	--search-icon-desktop-bg: rgba(var(--primary), 1);
	--search-icon-desktop-right-bg: transparent;
	--search-icon-desktop-right-icon: rgba(var(--primary-accent-yellow), 1);

	// Desktop search results
	--search-no-results-desktop-text-font: var(--h3);
	--search-no-results-desktop-text-color: rgba(var(--base-01), 1);

	// Section Head
	--main-icon: var(--base-01);
	--main-title: rgba(var(--font-light), 1);
	--main-subtitle: rgba(var(--font-supportive), 1);
	--inverted-main-icon: rgba(var(--base-11), 1);
	--inverted-main-title: rgba(var(--base-11), 1);
	--inverted-main-subtitle: rgba(var(--base-11), 1);
	--section-head-main-icon: rgba(var(--primary-accent-blue-light), 1);

	// Game Tags
	--hot-bg: rgba(var(--primary-accent-yellow), 1);
	--hot-text: rgba(var(--font-dark), 1);
	--new-bg: rgba(var(--primary-accent-cyan), 1);
	--new-text: rgba(var(--font-dark), 1);
	// --private-text: rgba(var(--base-11), 1);
	// --vip-text: defaults to --base-02

	--game-tag-background-color--EXCLUSIVE: rgba(var(--primary-accent-cyan), 1);
	--game-tag-text-color--EXCLUSIVE: rgba(var(--font-dark), 1);
	--provider-name-line-height: 15px;
	--game-tag-line-height: 17px;

	// Sign In
	--sign-in-box: rgba(var(--primary-accent-blue-dark), 1);
	--sign-in-box-border: rgba(var(--primary-accent-blue-dark), 1);

	// Tabs
	--product-tab: rgba(var(--primary-accent-blue), 1);
	--product-tab-content: rgba(var(--font-supportive), 1);
	--product-tab-border-top: rgba(var(--primary-accent-blue), 1);

	// Sidebar
	--sidebar-bg: rgba(var(--primary-accent-blue), 1);
	--sidebar-box: rgba(var(--primary-accent-blue-dark), 1);
	--sidebar-shadow: rgba(var(--base-12), 0.2);
	// --sidebar-border-left: defaults to the same color as sidebar-bg

	// Footer
	--footer-top-border: rgba(var(--primary-accent-blue-dark));
	--footer-icon: rgba(var(--base-01), 1);
	--footer-bg: rgba(var(--primary-accent-blue), 1);
	--footer-box: rgba(var(--primary-accent-blue-dark), 1);
	--footer-text: var(--font-supportive);
	--footer-link-color: var(--font-light);
	--language-text: rgba(var(--font-light), 1);
	--footer-payment-icon: rgba(var(--primary-accent-blue-light), 1);
	--footer-payment-logo-bg: rgba(var(--base-01), 1);
	--footer-social-icon: rgba(var(--primary-accent-blue-light), 1);
	--footer-18-icon: rgba(var(--error), 1);
	--footer-18-icon-text: rgba(var(--font-dark), 1);
	--footer-logo-18-height: 45px;
	--footer-logo-18-width: 45px;
	--footer-logo-color: var(--primary-accent-grey-dark);
	// --footer-flag-language-text: defaults to --label-regular;
	--footer-link-continue-icon-color: rgba(var(--primary-accent-yellow), 1);
	--flag-eu: rgba(var(--primary-accent-grey-dark), 1);
	--flag-eu-dots: rgba(var(--primary-accent-blue), 1);

	// Hero
	--hero-bg: rgba(var(--primary-accent-blue-dark), 1);
	--hero-img: var(--brandContainerBackground);
	--hero-font-color: rgba(var(--font-light), 1);
	--hero-pattern-size: 74px 35px;
	--hero-bg-position: left top;
	--hero-bg-repeat: repeat;

	// SEO-content 3
	--seo-read-more-btn: var(--font-brand);
	--seo-content-border-top: rgba(var(--primary-accent-blue-dark), 1);
	//--seo-content-border-bottom: rgba(var(--primary-accent-blue-dark), 1);
	--seo-content-bg: rgba(var(--primary-accent-blue));

	// Loader
	--loading-animation-dots-color: rgba(var(--primary-accent-yellow), 1);

	// Card
	--box-bg: rgba(var(--primary-accent-blue-dark), 1);
	--browse-circle-bg: rgba(var(--primary-accent-blue-dark), 1);
	--browse-circle-icon: rgba(var(--primary-accent-yellow), 1);
	--gameCardDetailBg: var(--primary-accent-blue);
	--open-in-new-window-icon: var(--font-supportive);

	// New Game Card
	--gameCardTitleDetailColor: var(--font-light);
	--gameCardTitleDetailFont: var(--label-regular);
	--gameCardDotBg: var(--primary-accent-yellow);
	--gameCardTitle: var(--base-01);
	--gameCardTitleSmall: var(--font-supportive);
	--gameCardWinnerBg: var(--primary-accent-blue);
	--gameCardJackpotBg: var(--primary-accent-blue);
	--gameCardJackpotText: rgba(var(--font-light), 1);
	--gameCardJackpotFont: var(--label-bold);
	--gameCardJackpotFontSmall: var(--small-bold);

	// Game Info
	--gameInfoBg: var(--primary-accent-blue);
	--gameInfoTitleName: var(--font-light);
	--gameInfoTitleProvider: var(--font-supportive);
	--gameInfoLoveButton: var(--primary-accent-blue-dark);
	--gameInfoLoveIcon: var(--primary-accent-blue-light);
	--gameInfoDescText: var(--font-light);
	--gameInfoButtonBorder: var(--primary-accent-yellow);
	--gameInfoButtonFont: var(--h4-bold);
	--gameInfoButtonText: var(--primary-accent-yellow);
	--gameInfoDetailBg: var(--primary-accent-blue-dark);
	--gameInfoDetailTitle: var(--font-supportive);
	--gameInfoDetailAmount: var(--font-light);
	--gameInfoPlayRealButtonBg: var(--primary-accent-yellow);
	--gameInfoPlayRealButtonText: var(--font-dark);
	--game-info-exit-icon-color: rgba(var(--primary-accent-yellow), 1);
	--game-info-exit-bg-color: rgba(var(--base-12), 0.8);

	// Explore Category Carousel
	--category-arrow-icon-color: rgba(var(--primary-accent-yellow), 1);
	--category-arrow-bg: var(--primary-accent-blue-dark);
	--category-title-color: var(--base-01);
	--category-link-color: var(--font-brand);

	// Explore Category Page
	--category-page-bg: var(--primary-accent-blue);
	--category-page-title-color: var(--base-01);
	--category-exit-icon-color: rgba(var(--primary-accent-yellow), 1);

	// Slider input
	--slider-empty-track: var(--base-03);
	--slider-thumb: rgba(var(--primary-dark), 1);
	--slider-filled-track: rgba(var(--primary-light), 1);

	// Virtuals
	// --virtuals-root-bg-color: var(--base-09);

	// Highlight-games
	// --highlight-games-bg-primary: defaults to rgba(var(--base-10), 1);
	// --highlight-games-bg-secondary: defaults to rgba(var(--base-11), 1);
	// --highlight-games-bg-tertiary: defaults to rgba(var(--base-12), 1);
	// --highlight-games-text-primary: defaults to rgba(var(--base-02), 1);
	// --highlight-games-text-secondary: defaults to rgba(var(--base-06), 1);
	// --highlight-games-text-disabled: defaults to rgba(var(--base-06), 1);
	// --highlight-games-text-secondary-disabled: defaults to rgba(var(--base-07), 1);
	// --highlight-games-betslip-balance-wrapper: defaults to rgba(var(--base-11), 1);
	// --highlight-games-betslip-balance-icon-color: defaults to rgba(var(--base-02), 1);
	// --highlight-games-betslip-border-color: defaults to rgba(var(--base-09), 1);
	// --highlight-games-betslip-header-text-color: defaults to rgba(var(--highlight-games-text-primary), 1);
	// --highlight-games-mobile-betslip-bg: defaults to var(--highlight-games-bg-tertiary);
	// --highlight-games-video-player-background: defaults to rgba(var(--base-12));
	// --highlight-games-mute-icon-color: defaults to rgba(var(--base-01));
	// --highlight-games-bet-tab-label-text-color: defaults to rgba(var(--base-02), 1);
	// --highlight-games-pending-bet-color: defaults to rgba(var(--pending), 1);
	// --highlight-games-bet-body-bg-color: defaults to rgba(var(--base-11);
	// --highlight-games-bet-betline-bg-color: defaults to rgba(var(--base-10);
	// --highlight-games-void-bet-color: defaults to rgba(var(--base-02);
	//--highlight-games-clear-bets-btn-color: defaults to rgba(var(--base-10), 1);
	// --highlight-games-market-count-text-color: defaults to rgba(var(--base-04), 1);
	// --highlight-games-bet-tab-text-color: rgba(var(--base-06), 1);
	// --highlight-games-week-event-border: defaults to 0;
	// --highlight-games-week-event-hover-border: defaults to 0;

	// Onboarding
	// --highlight-games-bg-secondary: defaults to rgba(var(--base-11), 1)
	// --onboarding-backdrop-bg: defaults to rgba(var(--base-11), 0.85);
	// --onboarding-item-bg: defaults to rgba(var(--base-10),1);

	// Sportsbook
	// StakeInput
	//--stake-input-button-bg-color: var(--button-primary-bg);
	//--stake-input-button-disabled-color: rgba(var(--base-10), 1);
	//--stake-input-bg-color: defaults to rgba(var(--base-12), 1);
	//--stake-input-text-color: defaults to rgba(var(--base-10), 1);
	--sportsbook-rules-link-bg-color: rgb(32, 32, 32);

	// Transparent bg
	--background-alpha: rgba(var(--primary), 0.75);

	// Split view
	--background-split-view: rgba(var(--base-11), 1);

	// Lotteries
	--lotteries-cash-svg-background: rgba(var(--base-09), 1);
	--lotteries-cash-svg-fill: rgba(var(--base-02), 1);

	// propose-locale
	//--propose-locale-text: defaults to grey-50;

	// Skeleton placeholders
	--skeleton-placeholder-bg: rgba(var(--base-09), 1);
	--skeleton-placeholder-bg-gradient: linear-gradient(
		-90deg,
		var(--skeleton-placeholder-bg) 0%,
		rgba(var(--base-05), 1) 50%,
		var(--skeleton-placeholder-bg) 100%
	);

	// Shop / Promotions
	--shop-points-balance-bg: rgba(var(--primary-accent-blue), 1);
	--shop-points-title-text-color: rgba(var(--font-supportive), 1);
	--shop-points-balance-text-color: rgba(var(--primary-accent-yellow), 1);
	--shop-points-balance-button-bg: rgba(var(--primary-accent-yellow), 1);
	--shop-points-balance-button-text: rgba(var(--primary-dark), 1);
	--bonus-item-detail-bg: rgba(var(--primary-accent-blue), 1);
	--bonus-item-foreground-text: rgba(var(--font-light), 1);
	--bonus-detail-pill-bg: rgba(var(--primary-accent-yellow), 1);
	--bonus-detail-close-icon-color: rgba(var(--primary-accent-yellow), 1);
	--bonus-split-icon-button-bg: rgba(var(--primary-accent-blue-dark), 1);
	--bonus-split-select-bg: rgba(var(--primary-accent-blue-dark), 1);
	--marketplace-split-icon-button-bg: rgba(var(--primary-accent-blue), 1);
	--shop-points-faq-button: rgba(var(--primary-accent-yellow), 1);
	--shop-filter-bar-bg: rgba(var(--primary-accent-blue-dark), 1);
	--bonus-welcome-package-item-read-more-button-box: rgba(var(--primary-accent-blue-dark), 1);
	--bonus-welcome-package-item-bg: rgba(var(--primary-accent-blue-dark), 1);
	--marketplace-points-bg: rgba(var(--primary-accent-yellow), 1);
	--marketplace-points-text: rgba(var(--font-dark), 1);
	--marketplace-sale-bg: rgba(var(--primary-accent-cyan), 1);
	--marketplace-sale-text: rgba(var(--font-dark), 1);
	--marketplace-sale-text-bg: rgba(var(--base-01), 0.1);
	--marketplace-info-text: rgba(var(--font-light), 1);
	--marketplace-wagering-text: rgba(var(--font-light), 1);
	--marketplace-card-bg: rgba(var(--primary-accent-blue-dark, 1));
	--marketplace-specials-bg: rgba(var(--primary-accent-blue-dark, 1));
	--marketplace-specials-card-bg: rgba(var(--primary-accent-blue, 1));
	--marketplace-chevron: rgba(var(--primary-accent-yellow, 1));
	--marketplace-chevron-bg: rgba(var(--primary-accent-blue-dark, 1));

	// Active bonus
	--active-bonus-progress-lane: rgba(var(--base-01), 1);
	--active-bonus-progress-bar: rgba(var(--primary-accent-cyan), 1);

	// Filter pills
	--filter-pills-bg: rgba(var(--primary-accent-blue-dark), 1);
	--filter-pills-bg-active: rgba(var(--primary-accent-blue-light), 1);
	--filter-pills-text-color-active: rgba(var(--font-dark), 1);
	--filter-pills-text-color: rgba(var(--font-supportive), 1);

	// Submenu items
	--submenu-bg--desktop: var(--filter-bg-secondary);
	--submenu-item-bg--desktop: rgba(var(--primary-accent-blue-dark), 1);
	--submenu-item-bg-active--desktop: rgba(var(--primary-accent-blue-light), 1);
	--submenu-item-text-color--desktop: rgba(var(--font-supportive), 1);
	--submenu-item-text-color-active--desktop: rgba(var(--font-dark), 1);
	--submenu-item-border-color--desktop: transparent;
	--submenu-item-border-color-active--desktop: transparent;
	--submenu-item-bg: rgba(var(--base-06), 1);
	--submenu-item-bg-active: rgba(var(--primary-accent-blue-light), 1);
	--submenu-item-text-color: rgba(var(--base-01), 1);
	--submenu-item-text-color-active: rgba(var(--font-dark), 1);
	--submenu-item-border-color: transparent;
	--submenu-item-border-color-active: transparent;

	// Cookie Banner
	--cookie-policy-link: rgba(var(--font-brand), 1);

	// Stepper (registration wizard)
	--stepper-active: rgba(var(--primary-accent-yellow), 1);
	--stepper-active-text: rgba(var(--base-02), 1);
	--stepper-inactive: rgba(var(--primary-accent-blue-dark), 1);
	--custom-stepper-active-color: rgba(var(--base-01), 1);
	--disabled: rgba(var(--font-supportive), 1);

	// Odds format toggle
	--odds-format-toggle-bg: rgba(var(--primary-accent-blue-dark), 1);

	//--policy-version-text: defaults to --language-text
	--cookie-toggle-bg-on: rgba(var(--primary-accent-yellow-dark), 1);
	--cookie-toggle-bg-off: rgba(var(--primary-accent-blue-light), 1);
	--cookie-toggle-knob-on: rgba(var(--primary-accent-yellow), 1);
	--cookie-toggle-knob-off: rgba(var(--primary-accent-blue-light), 1);
	--cookiebanner-wrapper-bg: rgba(var(--primary-accent-blue), 1);
	--cookie-policy-footer-bg: rgba(var(--primary-accent-blue), 1);
	--cookie-footer-policy-text: rgba(var(--font-light), 1);
	--cookie-footer-wrap-big-bg: rgba(var(--primary-accent-blue), 1);
	--cookie-footer-wrap-small-bg: rgba(var(--primary-accent-blue), 1);
	--cookie-accept-all-text-big: rgba(var(--font-dark), 1);
	--cookie-accept-all-text-small: rgba(var(--font-dark), 1);
	--cookie-save-settings-btn-text: rgba(var(--font-dark), 1);
	--cookie-banner-text: rgba(var(--font-light), 1);
	--cookie-accept-settings-text: rgba(var(--font-dark), 1);
	--cookie-accept-settings-small-text: rgba(var(--font-dark), 1);
	--cookie-save-settings-btn-bg: rgba(var(--primary-accent-yellow), 1);
	--cookie-small-save-settings-btn-bg: rgba(var(--primary-accent-yellow), 1);
	--cookie-small-save-settings-btn-text: rgba(var(--font-dark), 1);
	--cookie-banner-settings-link-lg: var(--label-bold);
	--cookie-link-supportive: rgba(var(--font-supportive), 1);
	--cookie-banner-logo: var(--page-head-logo);

	//campaign-registration
	--collapse-view-chevron: rgba(var(--primary-accent-yellow), 1);

	// UI package variables
	// ====================
	--scroll-area-scroll-indicator-color: var(
		--primary-accent-blue-dark
	); // Intentionally set this as rgb because we need to be able to use different alpha values.

	--floating-element-bg-color: rgba(var(--primary-accent-blue), 1);
	--floating-element-text-color: rgba(var(--base-10), 1);

	// Lists
	--list-item-background-color: var(--link-list);

	// Date picker
	--date-picker-background: rgba(var(--primary-accent-blue-dark), 1);
	--date-picker-outline: rgba(var(--primary-accent-blue-light), 1);
	--date-picker-selected: rgba(var(--primary-accent-yellow), 1);
	--date-picker-nav-button-icon: var(--primary-accent-yellow);
	--date-picker-text: rgba(var(--font-light), 1);
	--date-picker-text-supportive: rgba(var(--font-supportive), 1);
	--date-picker-text-on-selected: rgba(var(--font-dark), 1);

	// Cards
	--lc-card-font-size: 11px;
	--card-bg: rgba(var(--primary-accent-blue-dark), 1);
	--lc-live-casino-card-bg: rgba(var(--base-12), 0.8);
	// --card-header-caption-font: defaults to --caption;
	--card-header-caption-font-color: rgba(var(--base-05), 1);
	// --card-header-title-font: defaults to --label-bold;
	--card-header-title-font-color: rgba(var(--font-light), 1);
	--card-body-text-font-color: var(--font-supportive);
	// --card-media-pill-bg defaults to card-bg
	// --card-media-pill-font defaults to --caption
	// --card-media-pill-border-radius defautls 12px;
	--btn-dots: rgba(var(--primary-accent-yellow), 1);
	// --button-dot-active-bg: defaults to rgba(var(--primary-accent-yellow), 1)
	// --button-dot-bg: defaults to var(--button-secondary-bg, rgba(var(--base-08), 1))

	// Modal
	// --action-sheet-button-text-color: defaults to rgba(var(--base-02);
	// --action-sheet-button-bg: defaults to rgba(var(--base-08)

	//Scroll Down Overlay - SGA Sportsbook Relieve
	--scrollDownContainerBg: linear-gradient(0deg, #fff 0%, #f4f4f4 53.88%, rgba(244, 244, 244, 0%) 77.78%);

	// new-quick-deposit-toolbar
	--new-qd--back-button-icon-color: rgba(var(--primary-accent-yellow), 1);
	--new-qd--button-wrapper-background: rgba(var(--primary-accent-yellow), 1);
	--new-qd--text-color: rgba(var(--font-dark), 1);
	--new-qd--icon-container-color: var(--button-primary-text);

	// new-quick-deposit-modal
	--new-qd--change-method-color: rgba(var(--primary-accent-yellow), 1);
	--new-qd--amount-input-bg: rgba(var(--primary-accent-blue-dark), 1);
	--new-qd--amount-input-border-color: var(--primary-accent-blue-light);
	--new-qd--amount-input-focus-border-color: rgba(var(--primary-accent-yellow), 1);
	--new-qd--amount-input-focus-text-color: rgba(var(--primary-accent-yellow), 1);
	--new-qd--amount-input-text-color: rgba(var(--font-light), 1);
	--new-qd--disabled-amount-input-bg: rgba(var(--primary-accent-blue-dark), 1);
	--new-qd--font-color: var(--font-light);
	--new-qd--list-border-color: rgba(var(--primary-accent-blue-dark), 1);
	--new-qd--modal-bg: rgba(var(--primary-accent-blue), 1);
	--new-qd--modal-header-close-icon-bg: rgba(var(--primary-accent-white), 0.15);
	--new-qd--modal-header-close-icon-color: rgba(var(--primary-accent-white), 1);

	//quick-deposit-modal
	--qd-modal-contrast-text: var(--primary-accent-yellow);
	--qd-modal-cancel-text: var(--font-light);

	// quick deposit mobile toolbar
	--quick-deposit-modal-bg: rgba(var(--primary-accent-blue), 1);
	--quick-deposit-amount-input-bg: rgba(var(--primary-accent-blue-dark), 1);
	--quick-deposit-methods-collapse-bg: rgba(var(--primary-accent-blue-dark), 1);
	--quick-deposit--toolbar-background: rgba(var(--primary-accent-blue), 1);
	--quick-deposit--button-wrapper-background: var(--primary-accent-yellow);
	--quick-deposit--button-wrapper-border: var(--primary-accent-blue-dark);
	--quick-deposit--text-color: var(--font-dark);
	--quick-deposit--icon-container-color: var(--font-dark);
	--quick-deposit-icon-color: rgba(var(--primary-accent-blue-dark), 1);
	--swish-loader-dots: rgba(var(--primary-accent-blue), 1);

	//Player Info Screen
	--player-info-bg: rgba(var(--primary), 1);
	--player-info-champagne: rgba(var(--base-01), 1);
	--player-info-title: rgba(var(--base-01), 1);
	--player-info-desc: rgba(var(--font-supportive), 1);
	--player-info-cgr-bg: rgba(var(--primary-dark), 1);
	--player-info-cgr-icon: rgba(var(--primary-accent-blue-light), 1);
	--player-info-cgr-title: rgba(var(--font-light), 1);
	--player-info-cgr-desc: rgba(var(--base-01), 1);
	--player-info-balance-value: rgba(var(--base-01), 1);
	--player-info-balance-border: rgba(var(--primary-dark), 1);
	--player-info-balance-desc: rgba(var(--font-supportive), 1);

	// Carousel
	--carouselTitleFont: var(--h2);
	--carouselTitleFontLarge: var(--display-3);
	--carouselTitleColor: var(--font-light);
	--carouselDescFont: var(--label-regular);
	--carouselDescFontLarge: var(--h4-bold);
	--carouselDescColor: var(--font-light);
	--carouselDotBg: var(--primary-accent-blue-dark);
	--carouselDotActiveBg: var(--primary-accent-yellow);
	--carouselArrow: var(--primary-accent-yellow);
	--carouselArrowBg: rgba(var(--primary-accent-blue-dark), 1);

	//multiLayer Carousel
	--multiLayerCarouselButton-border-radius: 100px;
	--multiLayerCarouselTitleFont: var(--h3);
	--multiLayerCarouselTitleFontLarge: var(--display-2);
	--multiLayerCarouselDescFont: var(--caption);
	--multiLayerCarouselDescFontLarge: var(--h4-regular);
	--multiLayerCarouselButtonFont: var(--label-bold);
	--multiLayerCarouselButtonBgColor: rgba(var(--primary-accent-yellow), 1);
	--multiLayerCarouselButtonColor: var(--font-dark);
	--multiLayerCarouselButtonFontLarge: var(--h3);
	--bannerTermsConditionsBackground: rgba(var(--primary-accent-blue), 1);
	--bannerTermsConditionsOverlay: rgba(0, 0, 0, 80%);
	--bannerTermsConditionsColor: rgba(var(--font-light), 1);
	--bannerTermsConditionsFont: var(--small);

	// Binary Question
	--binary-question-inner-background: rgba(var(--primary-accent-blue), 1);
	--binary-question-divider-color: rgba(var(--primary-accent-blue-dark), 1);

	// tournaments
	--tournaments-sidebar-background: rgba(var(--primary-accent-blue), 1);
	--tournaments-sidebar-surface: rgba(var(--primary-accent-blue-dark), 1);
	--tournaments-sidebar-divider-on-background: rgba(var(--primary-accent-blue-dark), 1);
	--tournaments-sidebar-divider-on-surface: rgba(var(--primary-accent-blue), 1);
	--tournaments-text-color-overwrite: unset;
	--tournaments-text-on-tag: rgba(var(--font-light), 1);
	--tournaments-button-primary-bg: rgba(var(--font-brand), 1);
	--tournaments-rank-highlight: rgba(var(--primary-accent-cyan), 1);
	--tournaments-rank-on-highlight: rgba(var(--font-dark), 1);
	--tournaments-rank-avatar-on-background: rgba(var(--primary-accent-blue-dark), 1);
	--tournaments-rank-avatar-on-surface: rgba(var(--primary-accent-blue), 1);
	--tournaments-rank-avatar-on-highlight: rgba(var(--primary-accent-blue), 1);
	--tournaments-rank-avatar-win: rgba(var(--primary-accent-cyan), 1);
	--tournaments-rank-avatar-on-win: rgba(var(--font-dark), 1);
	--tournaments-rank-divider-on-background: rgba(var(--primary-accent-blue-dark), 1);
	--tournaments-results-title: rgba(var(--font-light), 1);
	--tournaments-results-trophy: rgba(var(--primary-accent-blue-light), 1);
	--tournaments-results-pill-icon: rgba(var(--font-light), 1);
	--tournaments-list-header-button: rgba(var(--font-brand), 1);
	--tournaments-list-item-details-button-text: rgba(var(--font-brand), 1);
	--tournaments-list-item-details-button-icon: rgba(var(--font-brand), 1);
	--tournaments-list-item-timer: rgba(var(--font-supportive), 1);
	--tournaments-modal-header-button-background: rgba(var(--font-light), 0.15);
	--tournaments-details-caption-alt: rgba(var(--font-supportive), 1);
	--tournaments-details-tab-border: rgba(var(--primary-accent-blue-light), 1);
	--tournaments-label-regular: normal normal 400 1.5rem/1.333 'Mulish', sans-serif;
	--tournaments-label-bold: normal normal 800 1.5rem/1.333 'Mulish', sans-serif;
	--tournaments-caption: normal normal 400 1.2rem/1.333 'Mulish', sans-serif;
	--tournaments-caption-bold: normal normal 800 1.2rem/1.333 'Mulish', sans-serif;
	--tournaments-small: normal normal 400 1.1rem/1.272 'Mulish', sans-serif;
	--tournaments-small-bold: normal normal 800 1.1rem/1.272 'Mulish', sans-serif;
	--tournaments-name-transform: none;

	// CSS filters for SVGs
	--filter-tab-unselected: var(--base-01);

	//bankid loading screen
	--bankid-loader-bg: rgba(var(--base-12), 0.8);
	--bankid-loader-title: rgba(var(--base-01), 1);
	--bankid-loader-desc: rgba(var(--base-01), 1);
	--bankid-loader-dots: rgba(var(--primary-accent-yellow), 1);

	// Static Text Page
	//--static-text-desktop-bg: rgba(var(--primary-accent-blue), 1);
	//--static-text-mobile-bg: rgba(var(--primary-accent-blue), 1);
	--document-title: rgba(var(--font-light), 1);
	--document-content-text: rgba(var(--font-light), 1);
	--document-content-list-text: rgba(var(--font-light), 1);
	--payment-method-top-text: rgba(var(--font-light), 1);
	--payment-method-row-text: rgba(var(--font-light), 1);

	// temporary registration
	--temporary-registration-icon: rgba(var(--primary-accent-yellow), 1);

	// Sun Template
	--base-modal-backdrop: rgba(var(--primary-dark), 0.8);
	--brand-border-radius: 100px;
	//--brand-label-weight: defaults to none
	// --brand-filter-pills-border-radius: defaults to 18px
	--campaign-iframe-head-bg: rgba(var(--primary), 1);
	//--campaign-iframe-close-svg: defaults to --primary-accent-yellow;
	--carousel-item-without-bottom-btn-bg: rgba(var(--primary), 1);
	--carousel-item-without-bottom-btn-text: rgba(var(--base-01), 1);
	// --carousel-item-without-bottom-title-color: defaults to --base-01
	// --carousel-item-without-bottom-sub-title-color: defaults to --base-01
	// --casino-cat-item-fav-icon: defaults to --secondary
	--casino-cat-item-fun-btn-bg: rgba(var(--secondary), 1);
	--casino-cat-item-fun-btn-text: rgba(var(--primary), 1);
	--casino-cat-item-jackpot-bg: rgba(var(--secondary), 1);
	--casino-cat-item-jackpot-sum: rgba(var(--primary), 1);
	--casino-cat-item-login-btn-bg: rgba(var(--primary-light), 1);
	--casino-cat-item-login-btn-text: rgba(var(--base-01), 1);
	// --casino-cat-item-title-color: defaults to —-secondary
	// --casino-cat-item-subtitle-color: defaults to —-base-05
	--casino-nav-bg: rgba(var(--base-02), 1);
	// --casino-navbar-active-border: defaults to transparent
	// --casino-navbar-bg: defaults to --base-02;
	// --casino-navbar-bg-active: defaults to transparent
	// --casino-navbar-link-border-radius: defaults to none
	// --casino-navbar-mobile-search-bg: defaults to none
	// --casino-navbar-sm-padding: defaults to 0
	// --casino-navbar-lg-padding: defaults to 36px
	--casino-navbar-text: rgba(var(--primary), 0.7);
	--casino-navbar-text-active: rgba(var(--primary), 1);
	// --cool-off-timer-bg: defaults to --info
	--cool-off-timer-color: var(--base-01);
	// --sun-banner-icon-wrapper: defaults to --primary-dark
	// --document-title: defaults to --primary
	// --document-content-text: defaults to --base-12
	// --document-content-list-text: defaults to --base-10
	// --footer-bottom-bg-desktop: defaults to --base-02
	// --footer-item-border-top-color: defaults to --base-12
	// --footer-item-border-bottom-color: //defaults to --base-09
	// --footer-res-gaming-title: defaults to --primary
	--footer-smaller-links-bg: rgba(var(--primary-dark), 1);
	// --header-balance: defaults to --base-03
	// --header-balance-sum: defaults to --base-01
	--header-login-box-btn-bg-disabled: rgba(var(--primary-light), 0.5);
	--header-login-box-btn-text-disabled: rgba(var(--base-01), 0.5);
	// --header-login-box-forgot-password: defaults to --base-01
	--hero-title: rgba(var(--secondary), 1);
	// --homepage-floating-banner-close: defaults to --base-01
	//--iframe-load-head-bg:
	// --ingame-frame-bg: defaults to --primary
	// --ingame-frame-game-title: defaults to --base-01
	--ingame-frame-icon-color: rgba(var(--secondary), 1);
	//--ingame-jurisdiction-color: defaults to --base-01
	// --marketplace-detail-close-bg: defaults to transparent
	// --marketplace-nav-header: defaults to transparent
	--marketplace-success-text-color: rgba(var(--base-01), 1);
	--marketplace-success-close-icon-color: rgba(var(--primary-accent-yellow), 1);
	--marketplace-progress-bg: rgba(var(--primary-accent-blue), 1);
	--marketplace-progress-bar-bg: rgba(var(--primary-accent-yellow), 1);
	--modal-wrapper-bg: rgba(var(--primary), 1);
	// --modal-wrapper-head-icon-desktop: defaults to --secondary
	--modal-wrapper-head-icon-mobile: rgba(var(--secondary), 1);
	--no-favorite-icon-fill: rgba(var(--secondary), 1);
	// --no-favorite-header-text: defaults to something
	--no-favorite-text: rgba(var(--base-01), 1);
	--payment-method-row-divider: rgba(var(--primary-light), 1);
	// --payment-method-top-text: defaults to --base-12
	// --payment-method-row-text: defaults to --primary
	--product-navbar-view-icon-active: rgba(var(--primary), 1);
	// --product-navbar-view-nav-bg: defaults to --nav-bg;
	--product-navbar-view-tab: rgba(var(--primary), 1);
	--product-navbar-view-tab-border-color: rgba(var(--primary-dark), 0.5);
	--product-navbar-view-tab-color: rgba(var(--base-01), 1);
	--product-navbar-view-tab-active: var(--nav-active);
	--product-navbar-view-tab-active-image: var(--nav-active);
	--product-navbar-view-tab-active-color: rgba(var(--primary), 1);
	// --product-navbar-view-tab-icon-color: defaults to --base-01
	--register-one-step-header-logo-width: 136px;
	--header-logo-width: 256px;
	--right-container-header-auth-bigger-deposit-btn: rgba(var(--secondary), 1);
	// --search-bar-mobile-wrapper-bg: defaults to --primary-dark
	--search-border-bottom-color-active: rgba(var(--secondary), 1);
	--search-input-wrapper-mobile: rgba(var(--primary-accent-blue-dark), 1);
	--search-result-desktop-text: rgba(var(--base-01), 1);
	--search-result-item-icon: rgba(var(--secondary), 1);
	// --shop-promotions-mobile-bg: defaults to transparent
	--smaller-footer-view-btn-wrap: rgba(var(--primary), 1);
	--ticker-body-bg: rgba(var(--base-01), 1);
	--ticker-body-border-color: rgba(var(--base-04), 1);
	// --ticker-header-bg: defaults to --primary-gradient
	// --ticker-item-sum: defaults to --primary
	// --ticker-item-text: defaults to --primary-light
	// --ticker-item-btn-bg: defaults to --primary-light
	// --ticker-item-btn-text: defaults to --base-01
	// --ticker-item-label-font: defaults to --label-regular
	// --tickers-box-border: defaults to transparent;
	// --tickers-title-color: defaults to --secondary
	// --vertical-carousel-bg: defaults to --base-03

	// Limits
	--limits-notification-icon: var(--primary-accent-yellow);
	--set-sync-limit-btn-border-color: rgba(var(--primary-accent-yellow), 1);
	--set-sync-limit-color: rgba(var(--primary-accent-yellow), 1);

	//notification-warning-banner
	--notif-banner-bg: rgba(var(--primary-accent-blue-dark), 1);
	--notif-banner-icon: rgba(var(--primary-accent-yellow), 1);

	// Responsible Gaming
	--time-limit-reached-label-color: var(--font-supportive);
	--time-limit-reached-timer-color: var(--font-light);

	// Credit Card
	--credit-card: rgba(var(--primary-dark), 1);
	--cc-indicator: rgba(var(--base-07), 1);
	--cc-indicator-active: rgba(var(--primary-dark), 1);
	--cc-card-add-btn: rgba(var(--base-04), 1);
	--cc-card-add-btn-text: rgba(var(--primary-dark), 1);

	// Sportsbook

	--sportsbook-rules-close-button-color: rgba(var(--primary-accent-yellow), 1);

	// Selection Button
	--selection-button-color: rgba(var(--primary-accent-blue-dark), 1);
	--selection-button-color-alternative: rgba(var(--primary-accent-blue-dark), 1);
	--selection-button-odds-text: rgba(var(--font-light), 1);
	--selection-button-selection-text: rgba(var(--font-supportive), 1);
	--selection-button-active-color: rgba(var(--primary-accent-yellow), 1);
	--selection-button-active-text-color: var(--button-primary-text);
	--selection-button-active-hover-bg-color: rgba(var(--primary-accent-yellow), 1);
	--selection-button-hover-bg-color: rgba(var(--primary-accent-yellow), 1);
	--selection-button-hover-text-color: rgba(var(--font-dark), 1);
	--selection-button-disabled-bg-color: rgba(var(--base-09), 1);
	--selection-button-disabled-text-color: rgba(var(--font-disabled), 1);

	// Transaction history

	--filter-pills-bg-th: var(--primary-accent-blue-dark);
	--filtering-pills-bg: var(--primary-accent-blue-light);
	--filtering-pills-disable: var(--primary-accent-blue-dark);
	--filtering-pills-hover: var(--primary-accent-blue-light);
	--filtering-pill-font-color: var(--font-dark);
	--filtering-pill-font-color-dark: var(--font-light);

	// Result products
	--result-products-label-color: rgba(var(--font-light), 1);

	// New change bank bottom popup
	--change-bank-account-popup-close-icon-fill: rgba(var(--base-01), 1);
	--change-bank-account-popup-close-icon-bg: rgba(var(--base-01), 0.2);
	--change-bank-account-popup-bg: rgba(var(--base-06), 1);

	// New input colors
	--input-background-color: var(--primary-accent-blue-dark);
	--input-disabled-background-color: var(--primary-accent-blue-dark);
	--input-border-color: var(--primary-accent-blue-light);
	--input-border-hover-color: var(--primary-accent-blue-light);
	--input-border-focus-color: var(--primary-accent-yellow);
	--input-border-disabled-color: var(--primary-accent-blue-light);
	--input-border-error-color: var(--error);
	--input-placeholder-color: var(--font-supportive);
	--input-placeholder-disabled-color: var(--font-supportive);
	--input-font-color: var(--font-light);
	--input-font-focus-color: var(--primary-accent-yellow);
	--input-font-disabled-color: var(--primary-accent-blue-light);
	--input-error-color: var(--error);
	--input-helper-text-color: var(--font-light);
	--select-arrow-color: var(--primary-accent-yellow);
	--toggle-group-selected-color: var(--font-brand);
	--toggle-group-color: var(--font-supportive);

	// Support nav
	--opacity-overlay: var(--primary-accent-blue-dark);
	--propose-locale-head-bg: var(--primary-accent-blue);
	--propose-locale-border: var(--primary-accent-blue-dark);
	--propose-locale-text: var(--font-light);
	--marketplace-success-main-icon: var(--section-head-main-icon);

	// Ingame Desktop view
	--ingame-nav-title: rgba(var(--font-light), 1);
	--ingame-jurisdiction-color: rgba(var(--font-light), 1);
	--ingame-nav-icons: rgba(var(--primary-accent-yellow), 1);
	--ingame-border-left-line: rgba(var(--base-01), 0.5);

	//Mentor UI Gaming Habits -- Playing Behavior box --
	--button-selection-days-select-color: rgba(var(--primary-accent-blue-light), 1);
	--button-selection-days-color: rgba(var(--primary-accent-blue-dark), 1);
	--playing-behaviour-pills-text-selected: rgba(var(--font-dark), 1);
	--playing-behaviour-description-text: rgba(var(--font-supportive), 1);
	--playing-behaviour-percentage-text: var(--font-disabled);
	--game-type-icon-color: var(--primary-accent-blue-light);
	--your-behaviour-box-chart: rgba(var(--primary-accent-blue-light), 1);
	--comparable-players-box-chart: rgba(var(--primary-accent-blue-dark), 1);
	--box-info-data-player: rgba(var(--primary-accent-blue-dark), 1);
	--vertical-line-others: rgba(var(--primary-accent-yellow), 1);

	// Thunderbite
	--thunderbite-modal-bg: rgba(var(--primary-dark), 0.8);
	--thunderbite-close: rgba(var(--primary-accent-yellow), 1);

	// Transaction history notification
	--transaction-history-notification-background-color: rgba(var(--primary-accent-grey-dark), 1);
	--transaction-history-notification-icon-color: var(--font-light);

	//detailed balance component
	--account-menu-balance-table-border: rgba(var(--primary));
	--account-menu-balance-more-info-text: rgba(var(--font-light));
	--header-balance-popover-background: rgba(var(--primary-dark));
	--header-balance-deposit-text: rgba(var(--font-light));
	--header-balance-deposit-icon: rgba(var(--secondary));

	// Communication popup
	--communication-popup-background-color: rgba(var(--primary), 1);
	--communication-popup-title-color: rgba(var(--font-light), 1);

	// Game Info
	--game-info-section-background-color: rgba(var(--primary), 1);
	--game-info-separator-color: rgba(var(--primary), 1);
	--game-info-background-color: rgba(var(--primary-dark), 1);
	--game-info-button-background-color: rgba(var(--primary), 1);
	--game-info-card-color: rgba(var(--primary-dark), 1);
	--game-info-menu-background: rgba(var(--primary-dark), 1);
	--game-info-menu-icon-color: rgba(var(--secondary), 1);
	--game-info-volatility-icon-color: rgba(var(--secondary), 1);
	--game-info-jackpot-amount-text-color: rgba(var(--font-light), 1);
	--game-info-row-value-text-color: rgba(var(--font-light), 1);
	--game-info-name-text-color: rgba(var(--font-light), 1);
	--game-info-provider-text-color: rgba(var(--font-light), 0.6);
	--game-info-collapse-header-text-color: rgba(var(--font-light), 1);
	--game-info-play-button-background-color: rgba(var(--secondary), 1);
	--game-info-play-button-color: rgba(var(--font-dark), 1);
}

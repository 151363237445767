:root {
	--sportsbook-bg-primary: rgba(var(--primary-accent-blue), 1);
	--sportsbook-bg-secondary: rgba(var(--primary-accent-blue-dark), 1);
	--sportsbook-bg-tertiary: rgba(var(--base-09), 1);
	--sportsbook-border-primary: rgba(var(--primary-accent-blue-dark), 1);
	--sportsbook-border-secondary: rgba(var(--primary-accent-blue), 1);

	// Top Bar
	--sportsbook-top-bar-bg-color: var(
		--primary-accent-blue-dark
	); // Related to --sportsbook-bg-secondary. Intentionally set this as rgb because we need to be able to use different alpha values.

	// Sportsbook Maintenance
	--sportsbook-maintainance-product-bg-color: rgba(var(--primary-accent-blue), 1);

	// Search
	--sportsbook-search-input-icon-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-search-input-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-search-input-placeholder-color: rgba(var(--font-supportive), 1);
	--sportsbook-search-close-button-text-color: rgba(var(--primary-accent-yellow), 1);

	// Desktop menu:
	--sportsbook-desktop-menu-item-icon-color: rgba(var(--primary-accent-blue-light), 1);
	--sportsbook-desktop-menu-chevron-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-desktop-menu-header-link-color: rgba(var(--primary-accent-yellow), 1);

	// Explore
	--sportsbook-explore-featured-link-bg-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-explore-featured-link-text-color: rgba(var(--primary-accent-blue-light), 1);
	--sportsbook-explore-featured-live-icon-color: rgba(var(--primary-accent-blue-light), 1);

	// Game card
	--sportsbook-game-card-list-header-title-color: rgba(var(--font-light), 1);
	--sportsbook-game-card-header-icon-color: rgba(var(--primary-accent-blue-light), 1);
	--sportsbook-odds-updating-bg: rgba(var(--base-09), 1);
	--sportsbook-odds-updating-text: rgba(var(--font-disabled), 1);

	// Betslip
	--sportsbook-minimized-betslip-header-bg-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-betslip-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-betslip-header-bets-count-circle-color: rgba(var(--primary-accent-blue-light), 1);
	--sportsbook-minimized-betslip-header-bets-count-circle-color: rgba(var(--primary-accent-blue-dark), 1);
	--sportsbook-minimized-betslip-header-bets-count-label-color: rgba(var(--font-light), 1);
	--sportsbook-minimized-betslip-header-title-color: rgba(var(--primary-accent-blue-dark), 1);
	--sportsbook-minimized-betslip-header-icon-color: rgba(var(--primary-accent-blue-dark), 1);
	--sportsbook-betslip-header-bets-count-label-color: rgba(var(--font-dark), 1);
	--sportsbook-betslip-radio-button-checked-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-betslip-header-icon-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-is-prominent-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-betslip-label-supportive-text: rgba(var(--font-light), 1);
	--sportsbook-share-betslip-copy-input-bg: rgba(var(--primary-accent-blue-dark), 1);
	--sportsbook-betslip-message-text-color: rgba(var(--base-02), 1);
	--sportsbook-betslip-message-action-button-text-color: rgba(var(--base-02), 1);
	--sportsbook-navigation-tab-disabled-color: rgba(var(--font-disabled), 1);
	--sportsbook-betslip-system-bets-title-bg: rgba(var(--primary-accent-blue-dark), 1);
	--sportsbook-betslip-clear-selections-button-text: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-betslip-freebet-select-bg: var(--sportsbook-bg-primary);
	--sportsbook-betslip-border: rgba(var(--primary-accent-blue-dark), 1);
	--sportsbook-betslip-icon-color: rgba(var(--primary-accent-blue-light), 1);
	--sportsbook-betslip-placement-my-bets-label-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-betslip-message-action-button-bg-color: rgba(13, 39, 109, 80%);
	--sportsbook-betslip-placement-interaction-text-info-color: rgba(var(--font-light), 1);

	// Event page
	--sportsbook-event-statistics-icon-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-navigation-back-button-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-event-dropdown-icon-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-event-scoreboard-total-score-bg: rgba(var(--base-07, 1));
	--sportsbook-event-scoreboard-serve-indicator: rgba(var(--primary-accent-yellow), 1);

	// Sport page
	--sportsbook-inline-date-picker-title-selected-color: rgba(var(--primary-accent-yellow), 1);

	// Navigation
	--sportsbook-navigation-tab-active-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-navigation-tab-border-color: var(--sportsbook-border-secondary);

	// Event Widget
	--sportsbook-event-widget-button-color: rgba(var(--font-supportive), 1);
	--sportsbook-login-message-button: rgba(var(--primary-accent-yellow), 1);

	// Carousel
	--sportsbook-carousel-promotion-combo-button-bg-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-carousel-promotion-combo-button-bg-color-hover: rgba(var(--primary-accent-yellow-light), 1);

	// My bets
	--sportsbook-my-bets-share-button-bg: rgba(var(--primary-accent-blue-dark), 1);
	--sportsbook-my-bets-share-text: rgba(var(--font-light), 1);
	--sportsbook-my-bets-share-icon: rgba(var(--font-light), 1);
	--sportsbook-my-bets-share-bet-card-icon-fill: rgba(var(--primary-accent-blue-dark), 1);
	--sportsbook-my-bets-cashout-button-text-color-active-hover: rgba(var(--font-dark), 1);
	--sportsbook-my-bets-cashout-button-text-color-active: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-my-bets-login-prompt-icon-color: rgba(var(--primary-accent-blue-light), 1);

	// Other
	--sportsbook-live-color: rgba(var(--primary-accent-cyan), 1);
	--sportsbook-live-text-color: rgba(var(--font-dark), 1);
	--sportsbook-event-status-indicator-text-color: rgba(var(--base-02), 1);
	--sportsbook-betslip-placement-interaction-text-error-color: rgba(var(--font-light), 1);
	--sportsbook-my-bets-expand-color: var(--primary-accent-blue);
	--sportsbook-horizontal-filter-bar-selected-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-browse-link-sport-icon-color: rgba(var(--primary-accent-blue-light), 1);
	--sportsbook-browse-link-icon-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-icon-primary: rgba(var(--primary-accent-yellow), 1);
	--selected-betslip-bonus-color: rgba(var(--base-02), 1);
	--bet-selected-bonus-color: var(--primary-accent-blue-light);
	--sportsbook-carousel-slider-icon-fill: var(--primary-accent-yellow);
	--sportsbook-event-bet-builder-prompt-icon-color: rgba(var(--primary-accent-blue-light), 1);
	--sportsbook-betslip-radio-button-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-browse-leagues-expanded-color: rgba(var(--primary-accent-yellow), 1);
	--sportsbook-highlighted-label-color: rgba(var(--font-light), 1);
}
